import { useContext } from "react"
import classNames from "classnames"
import map from "lodash/map"

import Section from "components/core/Section"
import Text from "components/core/Text"

import { DropdownContext } from "../"

import ListItem from "./list/ListItem"
import styles from "../styles.module.scss"
import useTranslation from "hooks/useTranslation"

const List = () => {
  const {
    top,
    width,
    items,
    variant,
    listContainerRef,
    setIsHoveringList,
    loading,
    userMustType,
  } = useContext(DropdownContext)
  const { t: trans } = useTranslation("common")

  return (
    <Section
      data_testid={"dynamic-dropdown-list-container"}
      className={classNames({
        [styles.listContainer]: variant === "borders",
      })}
      style={{ top: top || 0, width: width || "100%"}}
      ref={listContainerRef}
      onMouseEnter={() => setIsHoveringList(true)}
      onMouseLeave={() => setIsHoveringList(false)}
    >
      <Section className={styles.list}>
        {loading ? (
          <p>{trans("Loading") + "..."}</p>
        ) : (
          <>
            {items.length ? (
              map(items, (item, i) => (
                <ListItem key={item.key || i} item={item} itemIndex={i} />
              ))
            ) : (
              <Text textAlign="center">
                {userMustType
                  ? `${trans("Type something")}...`
                  : trans("No results")}
              </Text>
            )}
          </>
        )}
      </Section>
    </Section>
  )
}

export default List
