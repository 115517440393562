import PropTypes from "prop-types"
import PasswordField from "../Forms/fields/PasswordField"
import TextField from "../Forms/fields/TextField"
import styles from "../Forms/fields/TextField.module.scss"

const propTypes = {
  /** In our context is represented by Formik */
  form: PropTypes.object,
  /** In our context is represented by Formik */
  field: PropTypes.object,
  /** Used to access the text field directly */
  innerRef: PropTypes.object,
  type: PropTypes.string
}

// eslint-disable-next-line react/display-name
const TextFieldFormik = (props) => {
  const { form, field, type, innerRef, ...inputProps } = props
  const Field = type === "password" ? PasswordField : TextField

  return (
    <>
      <Field
        ref={innerRef}
        value={field.value}
        onBlur={field.onBlur(field.name)}
        onChange={field.onChange(field.name)}
        {...inputProps}
      />
      {form.touched[field.name] && form.errors[field.name] ? (
        <div className={styles.error}>{form.errors[field.name]}</div>
      ) : null}
    </>
  )
}

TextFieldFormik.propTypes = propTypes

export default TextFieldFormik
