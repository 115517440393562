import { forwardRef } from "react"
import { useField } from "formik"
import PropTypes from "prop-types"

import Text from "components/core/Text"
import DynamicDropdown from "components/core/DynamicDropdown"

import styles from "../Forms/fields/TextField.module.scss"

const propTypes = {
  name: PropTypes.string,
  shouldHandleError: PropTypes.bool,
}

const DynamicDropdownFormik = forwardRef(
  ({ name, shouldHandleError = true, ...rest }, ref) => {
    const [field, meta, helpers] = useField(name)

    const hasError =
      meta.touched &&
      typeof meta.error !== "undefined" &&
      Object.keys(meta.error).length !== 0

    return (
      <>
        <DynamicDropdown
          ref={ref}
          inputValue={field.value}
          hasError={hasError}
          onOptionSelected={(item) => {
            helpers.setTouched(true)
            helpers.setValue(item)
            if (rest.onSelectionChange) {
              rest.onSelectionChange(item)
            }
          }}
          {...rest}
        />

        {shouldHandleError && hasError && (
          <Text mT="xs" color="danger" className={styles.error}>
            {meta.error}
          </Text>
        )}
      </>
    )
  }
)

DynamicDropdownFormik.displayName = "DynamicDropdownFormik"
DynamicDropdownFormik.propTypes = propTypes

export default DynamicDropdownFormik
