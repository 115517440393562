import { useEffect, useState } from "react"
import filter from "lodash/filter"
import get from "lodash/get"

export const useFilteredItems = ({ items, itemKeyToFilter, searchTerm }) => {
  const [filteredItems, setFilteredItems] = useState([])

  useEffect(() => {
    if (!searchTerm) {
      return setFilteredItems(items)
    }

    const newItems = filter(items, (item) => {
      const normalizedText = get(item, itemKeyToFilter)

      return normalizedText?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    })

    setFilteredItems(newItems)
  }, [searchTerm, items])

  return [filteredItems]
}

export const useScrollHighlightedItemIntoView = ({
  highlightedItemRef,
  listContainerRef,
  showList,
  updateScrollPosition,
  setPreventHighlightMouseEnter,
  highlightIndex,
}) => {
  // update the scroll position of the content, based on the position of the highlighted index
  useEffect(() => {
    let timeout = null

    // bring the selected item into view when browsing the list with the arrow keys
    if (highlightedItemRef.current && showList && updateScrollPosition) {
      if (
        highlightedItemRef.current.offsetTop +
          highlightedItemRef.current.offsetHeight >=
        listContainerRef.current.offsetHeight + listContainerRef.current.scrollTop
      ) {
        listContainerRef.current.scrollTop =
          highlightedItemRef.current.offsetTop +
          highlightedItemRef.current.offsetHeight -
          listContainerRef.current.offsetHeight +
          15
      } else if (
        highlightedItemRef.current.offsetTop < listContainerRef.current.scrollTop
      ) {
        listContainerRef.current.scrollTop =
          highlightedItemRef.current.offsetTop - 15
      }
      // re-enable highlight on scroll after some time
      timeout = setTimeout(() => setPreventHighlightMouseEnter(false), 500)
    }

    return () => clearTimeout(timeout)
  }, [highlightIndex, showList, updateScrollPosition])
}
