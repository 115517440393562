import PropTypes from "prop-types"

const propTypes = {
  brTL: PropTypes.string,
  brBL: PropTypes.string,
  brTR: PropTypes.string,
  brBR: PropTypes.string,
  /** class for the dynamic dropdown container */
  className: PropTypes.string,
  /** when this property exists, the top position of the list is calculated based on the refs */
  hasRelativeParent: PropTypes.bool,
  /** An array of items */
  items: PropTypes.array.isRequired,
  /** Wether or not the items are being loaded */
  loading: PropTypes.bool.isRequired,
  /** The text displayed in the input field. State is defined in parent because it may need to be debounced - as it is for search dropdown */
  searchTerm: PropTypes.string.isRequired,
  /** Function to set that text  */
  setSearchTerm: PropTypes.func.isRequired,
  /** The selected value - when dropdown is used in a field, this will be the field's value. This is passed from parent and will overwrite/set the selected item and searchTerm.
   *
   * The keys in this object are usually `value` and `label`. If the key `section` is present then section titles will be displayed; items are assumed to be ordered by section, because each time the item's section is different than the previous item's section, the title will be displayed   */
  inputValue: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    section: PropTypes.string,
  }),
  /** Function for when an option is selected - should set the value of the Dropdown */
  onOptionSelected: PropTypes.func.isRequired,
  /** Placeholder for search input */
  placeholder: PropTypes.string,
  /** Show the user a text letting them know that they need to start typing */
  userMustType: PropTypes.bool,
  onFocus: PropTypes.func,
  parent: PropTypes.object,
  variant: PropTypes.oneOf(["shadows", "borders", "shadows_sm"]),
  colorScheme: PropTypes.oneOf(["blues", "greys"]),
}

export default propTypes
