import { useContext } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Section from "components/core/Section"
import Text from "components/core/Text"
import Flex from "components/core/Flex"

import styles from "../../styles.module.scss"
import { DropdownContext } from "../../"

const propTypes = {
  item: PropTypes.object,
  itemIndex: PropTypes.number,
}

const ListItem = ({ item, itemIndex }) => {
  const {
    items,
    selectedItem,
    highlightIndex,
    selectItem,
    highlightedItemRef,
    setHighlightIndex,
    setUpdateScrollPosition,
    preventHighlightMouseEnter,
  } = useContext(DropdownContext)

  return (
    <>
      {item.section &&
        (itemIndex === 0 || item.section !== items[itemIndex - 1].section) && (
          // TODO add section styles
          <Text className={styles.sectionItem} color="brandLightGrey">
            {item.section}
          </Text>
        )}
      <Section
        className={classNames("pointer", styles.listItem)}
        ref={itemIndex === highlightIndex ? highlightedItemRef : null}
        onClick={() => {
          selectItem(item)
        }}
        onMouseEnter={() => {
          if (!preventHighlightMouseEnter) {
            setHighlightIndex(itemIndex)
            setUpdateScrollPosition(false)
          }
        }}
      >
        <Flex justifyContent="spaceBetween" style={item.labelDetailsStyle}>
          <Text
            wordBreak="breakWord"
            className={classNames({
              [styles.selectedItem]: selectedItem?.value === item.value,
              [styles.highlightedItem]: itemIndex === highlightIndex,
              [styles.listItem__textFull]: !item.labelDetails,
              [styles.listItem__textPartial]: item.labelDetails,
            })}
          >
            {item.label}
          </Text>
          {item.labelDetails && (
            <Section className={styles.labelDetails}>{item.labelDetails}</Section>
          )}
        </Flex>
        {item.labelExtra && (
          <Text className={styles.labelDetails}>{item.labelExtra}</Text>
        )}
      </Section>
    </>
  )
}

ListItem.propTypes = propTypes

export default ListItem
