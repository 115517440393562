import { useRef, useEffect, forwardRef } from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import colors from "styles/colors.module.scss"
import useTranslation from "hooks/useTranslation"
import { SearchIcon, CloseIcon } from "components/core/SVGs"
import styles from "./SearchField.module.scss"

import { borderRadiusClassName } from "components/core/helpers/border"

const propTypes = {
  /** placeholder text */
  placeholder: PropTypes.string,
  br: PropTypes.string,
  brTL: PropTypes.string,
  brBL: PropTypes.string,
  brTR: PropTypes.string,
  brBR: PropTypes.string,
  /** can send a classes for wrapper. If we want to have a different bg color
   * we can send a different class
   */
  wpClassName: PropTypes.string,
  /** Used to add extra style to input */
  inputClassName: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  autoFocusOnRender: PropTypes.bool,
  focusAfterX: PropTypes.bool,
  /**  Used to add extra style to icon wp */
  iconWpClassName: PropTypes.string,
  /** Function that will be called when enter is pressed */
  onSearch: PropTypes.func,
  /** Function that will be called when the value is changed */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  live: PropTypes.bool,
}

const SearchField = forwardRef(
  (
    {
      placeholder,
      br = "",
      brTL = "s",
      brBL = "s",
      brTR = "s",
      brBR = "s",
      wpClassName = "bg-grey",
      inputClassName = "fw-500",
      iconWpClassName = "",
      showCloseIcon = true,
      autoFocusOnRender = false,
      focusAfterX = true,
      onSearch = () => {},
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
      onClick = () => {},
      onKeyDown,
      value,
      live,
    },
    ref
  ) => {
    const inputRef = ref || useRef(null)
    const { t: trans } = useTranslation("common")

    const onChangeFn = (e) => {
      const newE = {
        ...e,
        target: { ...e.target, value: e.target.value.replace(/\s+/g, " ") },
      }
      onChange(newE)
    }

    useEffect(() => {
      autoFocusOnRender && inputRef.current?.focus()
    }, [])

    return (
      <div
        className={classNames(styles.searchField, wpClassName, {
          ...borderRadiusClassName(br, brTL, brBL, brTR, brBR),
        })}
      >
        <div
          className={classNames(
            styles.searchField__icon,
            iconWpClassName,
            "searchFieldIconWp"
          )}
        >
          <SearchIcon fill={value ? colors.darkestGrey : ""} />
        </div>
        <input
          ref={inputRef}
          className={classNames(
            styles.searchField__input,
            inputClassName,
            "searchFieldInput"
          )}
          type="text"
          placeholder={placeholder || trans("Search")}
          onChange={onChangeFn}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          value={value}
          onKeyUp={(e) => {
            if (e.key === "Enter" || live) {
              e.preventDefault()
              let val = e.target.value.replace(/\s+/g, " ")
              onSearch(val)
            }
          }}
          onKeyDown={(e) => {
            if (onKeyDown) {
              return onKeyDown(e)
            }

            e.target.value.length == 0 &&
              e.key === " " &&
              e.preventDefault() &&
              e.stopPropagation()
          }}
          autoComplete="off"
        />

        {showCloseIcon && (
          <div
            onClick={(e) => {
              e.preventDefault() && e.stopPropagation()
              onChange("")
              onSearch("")
              focusAfterX && inputRef.current?.focus()
            }}
            className={classNames(
              styles.searchField__closeIcon,
              "pointer",
              "searchFieldCloseButton"
            )}
          >
            {value && <CloseIcon fill={colors.darkestGrey} strokeWidth="1" />}
          </div>
        )}
      </div>
    )
  }
)

SearchField.propTypes = propTypes
SearchField.displayName = "SearchField"

export default SearchField
