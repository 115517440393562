export const paddingPropTypes = ["xs", "s", "m", "l", "xl", "xxl", "huge", "none"]

export const borderRadiusClassName = (br, brTL, brBL, brTR, brBR) => ({
  // border radius
  "br-4": br === "xs",
  "br-10": br === "s",
  "br-none": br === "none",

  // border radius top left
  "brTL-4": brTL === "xs",
  "brTL-10": brTL === "s",
  "brTL-none": brTL === "none",

  // border radius top left
  "brBL-4": brBL === "xs",
  "brBL-10": brBL === "s",
  "brBL-none": brBL === "none",

  // border radius top left
  "brTR-4": brTR === "xs",
  "brTR-10": brTR === "s",
  "brTR-none": brTR === "none",

  // border radius top left
  "brBR-4": brBR === "xs",
  "brBR-10": brBR === "s",
  "brBR-none": brBR === "none",
})
