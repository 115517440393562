import { forwardRef } from "react"
import PropTypes from "prop-types"
import styles from "./PasswordField.module.scss"
import { useState } from "react"
import { EyeHideIcon, EyeShowIcon } from "components/core/SVGs"
import classNames from "classnames"

const propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.elementType,
  ]),
  wpClassName: PropTypes.string,
  error: PropTypes.any,
  tooltip: PropTypes.string,
}

// eslint-disable-next-line react/display-name
const PasswordField = forwardRef(
  (
    { onChange = () => {}, placeholder, label, value, error, wpClassName, tooltip },
    ref
  ) => {
    const [type, setType] = useState(true)

    return (
      <div className={classNames(styles.formGroup, wpClassName)}>
        {label && <label className={styles.label}>{label}</label>}
        <p className={styles.tooltip}>{tooltip}</p>
        <div className={styles.passwordField}>
          <i onClick={() => setType(!type)}>
            {type ? <EyeShowIcon /> : <EyeHideIcon />}
          </i>
          <input
            ref={ref}
            className={styles.formControl}
            type={type ? "password" : "text"}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            autoComplete="off"
          />
          <div className={styles.error}>{error}</div>
        </div>
      </div>
    )
  }
)

PasswordField.propTypes = propTypes

export default PasswordField
