import { forwardRef, useContext } from "react"
import classNames from "classnames"

import SearchField from "components/core/Forms/fields/SearchField"
import styles from "../styles.module.scss"

import { DropdownContext } from "../"
import useTranslation from "hooks/useTranslation"

const DropdownSearchField = forwardRef((_props, ref) => {
  const {
    brTL = "xs",
    brBL = "xs",
    brTR = "xs",
    brBR = "xs",
    variant,
    searchTerm,
    placeholder,
    showList,
    selectedItem,
    selectItem,
    items,
    highlightIndex,
    openDropdown,
    onFocus,
    isHoveringList,
    onOptionSelected,
    setShowList,
    setSearchTerm,
    setHighlightIndex,
    setUpdateScrollPosition,
    setPreventHighlightMouseEnter,
    autoFocusOnRender,
  } = useContext(DropdownContext)

  const { t: trans } = useTranslation("common")

  const getWpClassName = () =>
    classNames({
      [styles.inputWpListOpen]: showList,
      [styles.inputWp]: variant === "borders",
      [styles.inputWpShadows]: variant === "shadows" || variant === "shadows_sm",
      [styles.inputWpListOpenShadows]:
        showList && (variant === "shadows" || variant === "shadows_sm"),
    })

  const getInputClassNames = () =>
    classNames({
      [styles.dropdownInput]: variant === "borders",
      [styles.dropdownInputShadows]:
        variant === "shadows" || variant === "shadows_sm",
    })

  const getIconWpClassName = () =>
    classNames({
      [styles.iconWp]: variant === "borders",
      [styles.iconWpShadows]: variant === "shadows" || variant === "shadows_sm",
    })

  return (
    <SearchField
      ref={ref}
      brTL={brTL}
      brBL={brBL}
      brTR={brTR}
      brBR={brBR}
      autoFocusOnRender={autoFocusOnRender}
      showCloseIcon={false}
      value={searchTerm}
      placeholder={placeholder || `${trans("Search")}...`}
      wpClassName={getWpClassName()}
      inputClassName={getInputClassNames()}
      iconWpClassName={getIconWpClassName()}
      onSearch={() => {
        if (showList) {
          selectItem(items[highlightIndex])
        } else {
          openDropdown()
        }
      }}
      onFocus={(e) => {
        openDropdown()
        onFocus && onFocus(e)
      }}
      onBlur={(e) => {
        if (!isHoveringList) {
          if (!e.target.value) {
            onOptionSelected(null)
          }

          // click outside the list
          setShowList(false)
          const restSearch = selectedItem?.label || ""
          if (searchTerm !== restSearch) {
            setSearchTerm("")
            onOptionSelected(null)
          }
        }
        // else we are clicking inside the list so we are selecting an item
        // and that case is dealt with inside the onClick function of the item
      }}
      onChange={(e) => {
        // content of search input changes
        if (!showList && e?.target?.value) {
          setShowList(true)
        }

        if (!e?.target?.value) {
          // onOptionSelected(null)
          // setShowList(false)
        }

        setSearchTerm(e?.target?.value || "")

        // select first item of the new filtered options
        setHighlightIndex(0)
      }}
      onKeyDown={(e) => {
        switch (e.keyCode) {
          // Arrow Up
          case 38:
            e.preventDefault()
            if (!showList) {
              setShowList(true)
            } else {
              /**
               * Set highlight to last index, if highlight is null
               */
              setHighlightIndex((index) =>
                highlightIndex > 0 ? index - 1 : items.length - 1
              )
              setUpdateScrollPosition(true)
              setPreventHighlightMouseEnter(true)
            }
            break
          // Arrow Down
          case 40:
            e.preventDefault()
            if (!showList) {
              setShowList(true)
            } else {
              /**
               * Set index to 0 if highlight index is null. Reset to 0 if index is
               * greater then the length of the array
               */
              setHighlightIndex((index) =>
                highlightIndex !== null && highlightIndex < items.length - 1
                  ? index + 1
                  : 0
              )
              setUpdateScrollPosition(true)
              setPreventHighlightMouseEnter(true)
            }
            break
          // Escape
          case 27:
            {
              e.preventDefault()
              // prevent closing the modal with esc
              e.stopPropagation()
              // close the list
              setShowList(false)
              // reset the search text to match the selected item
              const restSearch = selectedItem?.label || ""
              if (searchTerm !== restSearch) {
                setSearchTerm(restSearch)
              }
            }
            break
          default:
            break
        }
      }}
    />
  )
})

// here for the use ref -> component needs a name
DropdownSearchField.displayName = "DropdownSearchField"

export default DropdownSearchField
