import { forwardRef } from "react"
import PropTypes from "prop-types"
import styles from "./TextField.module.scss"
import classNames from "classnames"

const propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onWpClick: PropTypes.func,
  innerClassName: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wpClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  error: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  cols: PropTypes.number,
  rows: PropTypes.number,
}

// eslint-disable-next-line react/display-name
const TextField = forwardRef(
  (
    {
      wpClassName,
      innerClassName,
      onWpClick = () => {},
      onChange = () => {},
      onBlur = () => {},
      onKeyUp = () => {},
      onKeyPress = () => {},
      onKeyDown = () => {},
      placeholder,
      type = "text",
      label,
      value,
      autoFocus,
      maxLength,
      error,
      name,
      disabled = false,
      cols,
      rows,
      ...rest
    },
    ref
  ) => (
    <div className={`${styles.formGroup} ${wpClassName}`} onClick={onWpClick}>
      {label && <label className={styles.label}>{label}</label>}
      {type === "textarea" ? (
        <textarea
          cols={cols}
          rows={rows}
          name={name}
          ref={ref}
          disabled={disabled}
          className={classNames(innerClassName, styles.formControl)}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          autoComplete="off"
          autoFocus={autoFocus}
          maxLength={maxLength}
          {...rest}
        />
      ) : (
        <input
          name={name}
          ref={ref}
          disabled={disabled}
          className={classNames(innerClassName, styles.formControl)}
          type={type}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          autoComplete="off"
          autoFocus={autoFocus}
          maxLength={maxLength}
          {...rest}
        />
      )}
      <p className={styles.error}>{error}</p>
    </div>
  )
)

TextField.propTypes = propTypes

export default TextField
