import { useEffect } from "react"

const useSelectListFullHeight = (parent, child, isVisible) => {
  useEffect(() => {
    if (!isVisible || !parent) {
      return
    }

    const parentBounding = parent?.current?.getBoundingClientRect()
    const childBounding = child?.current?.getBoundingClientRect()

    if (!parentBounding || !childBounding) return 

    const relativeTopPos = childBounding?.top - parentBounding?.top

    const childMaxHeight = parentBounding.height - relativeTopPos

    child.current.style.maxHeight = `${childMaxHeight > 150 ? childMaxHeight : 150}px`
  }, [isVisible])
}

export default useSelectListFullHeight
