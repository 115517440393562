import {
  useState,
  useRef,
  useEffect,
  createContext,
  forwardRef,
  useMemo,
} from "react"
import classNames from "classnames"

import useSelectListFullHeight from "hooks/useSelectListFullHeight"

import Section from "components/core/Section"

import propTypes from "./propTypes"
import styles from "./styles.module.scss"
import List from "./components/List"
import DropdownSearchField from "./components/SearchField"
import { useScrollHighlightedItemIntoView } from "./hooks"

export const DropdownContext = createContext({})

const DynamicDropdown = forwardRef(
  (
    {
      brTL = "xs",
      brBL = "xs",
      brTR = "xs",
      brBR = "xs",
      className,
      hasRelativeParent,
      items,
      loading,
      searchTerm,
      setSearchTerm,
      placeholder,
      inputValue,
      onOptionSelected,
      onFocus,
      userMustType,
      parent,
      autoFocusOnRender,
      variant = "borders",
      colorScheme = "blues",
    },
    ref
  ) => {
    // state for list
    const [showList, setShowList] = useState(false)
    const [isHoveringList, setIsHoveringList] = useState(false)
    const listContainerRef = useRef(null)
    // highlighting current item
    const [selectedItem, setSelectedItem] = useState(inputValue)
    const highlightedItemRef = useRef(null)
    const [highlightIndex, setHighlightIndex] = useState(null)
    const [updateScrollPosition, setUpdateScrollPosition] = useState(false)
    const [preventHighlightMouseEnter, setPreventHighlightMouseEnter] =
      useState(false)

    useSelectListFullHeight(parent, listContainerRef, showList)

    useScrollHighlightedItemIntoView({
      highlightedItemRef,
      listContainerRef,
      showList,
      updateScrollPosition,
      setPreventHighlightMouseEnter,
      highlightIndex,
    })

    useEffect(() => {
      setSearchTerm(inputValue?.label || "")
      setSelectedItem(inputValue)
    }, [inputValue])

    const openDropdown = () => {
      let highlightedIndex = null

      if (selectedItem?.value) {
        // the highlighted index should be the selected item
        highlightedIndex = items.findIndex(
          (item) => item.value === selectedItem.value
        )
      }

      setShowList(true)
      setHighlightIndex(highlightedIndex)
      setUpdateScrollPosition(true)
    }

    const selectItem = (item) => {
      if (!item) {
        return
      }
      // update the value of the dropdown
      // this will in turn set the selectedItem and the searchTerm
      onOptionSelected(item)
      setSearchTerm(item.label)
      // close the list
      setShowList(false)
      setIsHoveringList(false)
    }

    const top = useMemo(
      () =>
        hasRelativeParent
          ? ref?.current?.getBoundingClientRect().bottom -
            parent?.current?.getBoundingClientRect().top
          : 35,
      [
        parent?.current?.getBoundingClientRect().top,
        ref?.current?.getBoundingClientRect().bottom,
      ]
    )

    const width = useMemo(
      () => (hasRelativeParent ? 279 : "100%"),
      [hasRelativeParent]
    )

    return (
      <Section
        className={classNames(styles.wrapper, className, {
          [styles.wrapper__shadows]: variant === "shadows",
          [styles.wrapper__shadows_sm]: variant === "shadows_sm",
        })}
      >
        <DropdownContext.Provider
          value={{
            brTL: brTL,
            brBL: brBL,
            brTR: brTR,
            brBR: brBR,
            top,
            width,
            items,
            loading,
            onFocus,
            variant,
            showList,
            searchTerm,
            selectItem,
            colorScheme,
            placeholder,
            setShowList,
            userMustType,
            selectedItem,
            openDropdown,
            setSearchTerm,
            highlightIndex,
            isHoveringList,
            listContainerRef,
            onOptionSelected,
            setIsHoveringList,
            setHighlightIndex,
            highlightedItemRef,
            setUpdateScrollPosition,
            preventHighlightMouseEnter,
            setPreventHighlightMouseEnter,
            autoFocusOnRender,
          }}
        >
          <DropdownSearchField ref={ref} />

          {showList && <List />}
        </DropdownContext.Provider>
      </Section>
    )
  }
)

// here for the forward ref
DynamicDropdown.displayName = "DynamicDropdown"
DynamicDropdown.propTypes = propTypes

export default DynamicDropdown
